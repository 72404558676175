import * as React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'

import './styles/aboutSideMenu.scss';

export default function AdmissionsOverviewBottomMenu() {


    const data = useStaticQuery(graphql`
        query admissionOverviewBottomMenuLinks {
            site {
                siteMetadata {
                    admissionsOverviewMenu {
                    link
                    name
                    }
                }
            }
        }
    `)
    return (
        <div id="admissionsOverviewBottomMenu">
            <Row className="p-4 p-md-5">
                <Col>
                    <Nav defaultActiveKey={data.site.siteMetadata.admissionsOverviewMenu.link} className="flex-column">
                        <h2 className="menu-title mb-xl-5">Graduate Admissions</h2>
                        <Row>
                        {
                            data.site.siteMetadata.admissionsOverviewMenu.map(link => (
                                <Col md={6}>
                                    <Nav.Item>
                                        <Stack direction="horizontal" gap={3}>
                                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/icons/menu-cursor.svg" className="img-fluid pe-1" alt="menu cursor" />
                                            <Link to={link.link}>
                                                <Nav.Link as="span" eventKey={link.link}>
                                                    {link.name}
                                                </Nav.Link>
                                            </Link>
                                        </Stack>
                                    </Nav.Item>
                                </Col>
                            ))
                        }
                        </Row>
                    </Nav>
                </Col>
            </Row>
        </div>
    )
}
